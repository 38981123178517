import { gql, useQuery } from '@apollo/client'
import { parseISO } from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { Form, Formik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import * as yup from 'yup'
import GenericServerError from '../components/GenericServerError'
import SubmitButton from '../components/SubmitButton'
import TextInput from '../components/TextInput'

const rapidResultQuery = gql`
  query RapidTestResulty($kitId: ID!) {
    rapidTestById(id: $kitId) {
      id
      result
      resultDate
    }
  }
`

const resultParser = (result) => {
  if (!result) return 'Waiting For Result - check back later'
  if (result === 'POSITIVE') return 'POSITIVE - SARS-CoV-2 virus detected'
  if (result === 'NEGATIVE') return 'NEGATIVE - SARS-CoV-2 virus NOT detected'
  else throw new Error('Unrecognised test result')
}

const RapidResult = () => {
  const [kitId] = useQueryParam('kitId', StringParam)
  const { refetch } = useQuery(rapidResultQuery, { skip: true })
  const [result, setResult] = useState()
  const formRef = useRef()

  useEffect(() => {
    const form = formRef.current
    if (kitId && form) {
      form.handleSubmit()
    }
  }, [kitId])

  return (
    <section className="section is-small pt-3">
      <div className="has-width-large margin-auto">
        <h1 className="title has-text-midnightBlue is-spaced">
          {result ? 'Your Rapid Test Result' : 'Get Your Rapid Test Result'}
        </h1>

        {result ? (
          <>
            <p className="is-size-4 has-text-orange">{resultParser(result.result)}</p>
            <p>
              <strong>Kit ID:</strong> {result.id.replace('Kit-', '')}
            </p>
            {result?.resultDate ? (
              <p>
                <strong>Result date:</strong>{' '}
                {format(utcToZonedTime(parseISO(result.resultDate), 'Europe/London'), 'dd/MM/yy HH:mm')}
              </p>
            ) : null}
          </>
        ) : (
          <Formik
            innerRef={formRef}
            initialValues={{ kitId: kitId || '', __form: '' }}
            validationSchema={yup.object().shape({
              kitId: yup
                .string()
                .label('Kit ID')
                .matches(
                  /^K?([a-zA-Z0-9][a-zA-Z0-9][a-zA-Z0-9][a-zA-Z0-9]-?[a-zA-Z0-9][a-zA-Z0-9][a-zA-Z0-9][a-zA-Z0-9])$/,
                  {
                    excludeEmptyString: true,
                    message: 'ID numbers are 8 or 9 letters & numbers. Please check you typed it correctly.',
                  }
                )
                .required(),
            })}
            onSubmit={async (values, { setFieldError }) => {
              try {
                const { data } = await refetch({ kitId: values.kitId })
                const { rapidTestById } = data

                console.log('**', data)
                if (!rapidTestById) {
                  setFieldError('kitId', 'That kit ID is not in the system, please check you typed it correctly')
                  return
                }

                setResult(rapidTestById)
              } catch (err) {
                typeof Rollbar !== 'undefined' && Rollbar.error('Rapid test search error', err)
                setFieldError('__form', err.message)
                throw err
              }
            }}
          >
            {({ errors, isSubmitting }) => (
              <Form>
                <TextInput name="kitId" label="Enter Your Kit ID" type="text" />

                {errors.__form && <GenericServerError />}

                <div className="field" style={{ paddingTop: 18 }}>
                  <SubmitButton className="is-midnightBlue is-rounded" submitting={isSubmitting}>
                    Get results
                  </SubmitButton>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </section>
  )
}

export default RapidResult
