import cx from 'classnames'
import { Link } from 'gatsby'

const GenericServerError = ({ className }: { className?: string }) => (
  <article className={cx('message is-danger', className)}>
    <div className="message-body">
      An error occurred, we've been notified and will look into it. You can try again a little later or{' '}
      <Link to="/contact">contact our support team.</Link>
    </div>
  </article>
)

export default GenericServerError
