import cx from 'classnames'

const SubmitButton = ({
  className,
  submitting = false,
  disabled = false,
  children,
  name = undefined,
  value = undefined,
  onClick = undefined,
  ...props
}) => (
  <button
    type="submit"
    name={name}
    value={value}
    disabled={submitting || disabled}
    onClick={onClick}
    className={cx(
      children ? 'button' : '',
      {
        'is-loading': submitting,
      },
      className
    )}
    {...props}
  >
    {children}
  </button>
)

export default SubmitButton
